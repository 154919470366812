import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/ryan.parr/Documents/Apps/sprinklr.design/src/templates/mdx-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`be natural`}</h3>
    <p>{`Use language that our customer’s are most familiar and comfortable with. Therefore, avoid using unnecessary figures of speech or technical jargons.`}</p>
    <h3>{`be simple`}</h3>
    <p>{`Keep it simple, stupid — be short and crisp, without comprising on clarity.`}</p>
    <h3>{`be consistent`}</h3>
    <p>{`Repeat and re-use terminologies keeping in mind our user’s vocabulary.`}</p>
    <h3>{`be descriptive`}</h3>
    <p>{`Do establish context each time and do not assume the obvious. For the sake of clarity and intuitiveness, comfortably use descriptions wherever needed.`}</p>
    <h3>{`be directional`}</h3>
    <p>{`Write to accomplish goals by always pointing towards a way forward. Avoid making the users feel helpless and anxious by providing a clear call to action stating the next steps.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      